.road-map-node {
  width: 20px;
  height: 20px;
  border: 4px solid #22ffff;
  border-radius: 12px;
}

.road-map-line {
  /* height: 300px; */
  width: 0px;
  border-right: 1px solid #22ffff;
}

.road-map-dotted-line {
  width: 0px;
  border-right: 1px dotted #22ffff;
}

.completed {
  background-color: #22ffff;
}

.in-progress {
  background-color: #222;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes color {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #22ffff;
  }
  100% {
    background-color: #222;
  }
}

.road-map-description {
  width: 300px;
  text-align: left;
}
